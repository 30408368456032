import gql from 'graphql-tag';

export default gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      intId
      name
      nameVn
      description
      descriptionVn
      details
      detailsVn
      imageUrl
      imageFilePath
      origin
      preservation
      unitPrice {
        vat
        value
        vatIncluded
      }
      uom
      category
      disabled
      createdAt
      updatedAt
      offMarket
      private
      internalCode
      tags
      limitedTimeOffer {
        price {
          value
        }
        period {
          start
          end
        }
      }
      brandId
      productInfoByRegion {
        itemId
        regionCode
        price
        inStock
        exclusive
        limitedTimeOffer {
          price {
            value
            vat
            vatIncluded
          }
          period {
            start
            end
          }
        }
      }
      medias {
        type
        url
        name
        isPrimary
      }
    }
  }
`;
